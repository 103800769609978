<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-1">
                <h5 class="mt-2">Mapa</h5>
              </div>
              <div class="col-sm-7">
                <button
                  type="button"
                  class="btn bg-frontera text-white mr-2 align-top py-1"
                  data-toggle="modal"
                  data-target="#modal_aside_mapa"
                  style="cursor: pointer"
                >
                  <i class="fas fa-search-location" style="font-size: 21px"></i>
                  <div class="text-center">
                    <span class="badge bg-info">Buscar</span>
                  </div>
                </button>
                <label class="badge bg-frontera text-white mr-2">
                  <input
                    type="checkbox"
                    v-model="traza"
                    @change="showDraggable()"
                  />
                  <i style="font-size: 22px" class="fas fa-route p-1"></i>
                  <div class="text-center">
                    <span class="badge bg-dark">Trazabilidad</span>
                  </div>
                </label>

                <label class="badge bg-frontera text-white mr-2">
                  <input
                    type="checkbox"
                    v-model="form.vehiculos_na"
                    @change="showCarros(1)"
                  />
                  <i
                    style="font-size: 22px"
                    class="fas fa-truck-moving p-1"
                    v-b-popover.hover.top=""
                    title="Vehículos Inactivos"
                  ></i>
                  <div class="text-center">
                    <span class="badge bg-success">Nacionales</span>
                  </div>
                </label>

                <label class="badge bg-frontera text-white mr-2">
                  <input
                    type="checkbox"
                    v-model="form.vehiculos_in"
                    @change="showCarros(1)"
                  />
                  <i
                    style="font-size: 22px"
                    class="fas fa-truck-moving p-1"
                    v-b-popover.hover.top=""
                    title="Vehículos Internos"
                  ></i>
                  <div class="text-center">
                    <span class="badge bg-warning">Internos</span>
                  </div>
                </label>
                <label class="badge bg-frontera text-white mr-2">
                  <input
                    type="checkbox"
                    v-model="form.vehiculos_inact"
                    @change="showCarros(1)"
                  />
                  <i
                    style="font-size: 22px"
                    class="fas fa-truck-moving p-1"
                    v-b-popover.hover.top=""
                    title="Vehículos Inactivos"
                  ></i>
                  <div class="text-center">
                    <span class="badge bg-danger">Inactivos</span>
                  </div>
                </label>
                <button
                  type="button"
                  class="btn btn-dark ml-2 align-top py-1"
                  @click="showCarros(1)"
                  style="cursor: pointer"
                >
                  <i style="font-size: 22px" class="fas fa-sync"></i>
                  <div class="text-center">
                    <span class="badge bg-info">Actualizar</span>
                  </div>
                </button>
              </div>
              <div class="col-sm-4">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Cs</li>
                  <li class="breadcrumb-item active">Consultas</li>
                  <li class="breadcrumb-item active">Mapa</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-12">
                    <gmap-map
                      :center="center"
                      :zoom="zoom"
                      :tilt="tilt"
                      :heading="heading"
                      style="width: 100%; height: 650px"
                    >
                      <gmap-marker
                        :key="index"
                        v-for="(m, index) in markersTraza"
                        :position="m.position"
                        :icon="m.icon"
                        :animation="2"
                        @click="toggleInfoWindow(m, index, m.info)"
                      ></gmap-marker>
                      <gmap-cluster
                        :gridSize="50"
                        :zoomOnClick="true"
                        :enableRetinaIcons="true"
                        :minimumClusterSize="3"
                        ref="cluster"
                        :imagePath="'/img/map_cluster/m'"
                      >
                        <gmap-marker
                          :key="index"
                          v-for="(m, index) in markers"
                          :position="m.position"
                          :icon="m.icon"
                          :animation="2"
                          @click="toggleInfoWindow(m, index, m.info)"
                        ></gmap-marker
                      ></gmap-cluster>
                      <gmap-info-window
                        :options="infoOptions"
                        :position="infoWindowPos"
                        :opened="infoWinOpen"
                        @closeclick="infoWinOpen = false"
                      >
                        <div v-html="infoContent"></div>
                      </gmap-info-window>
                      <gmap-polygon
                        v-for="poligono in poligonos"
                        :options="{ fillColor: poligono.color }"
                        :key="poligono.id"
                        :paths="poligono.path"
                        :editable="false"
                        :draggable="false"
                        @click="
                          toggleInfoWindowPoli(
                            poligono,
                            poligono.id,
                            poligono.info
                          )
                        "
                      />
                      <gmap-circle
                        v-for="circular in circunferencias"
                        :key="circular.id"
                        :center="circular.center"
                        :radius="circular.radius"
                        :info="circular.info"
                        @click="
                          toggleInfoWindowCir(
                            circular,
                            circular.id,
                            circular.info
                          )
                        "
                      ></gmap-circle>
                    </gmap-map>
                  </div>
                  <div
                    class="col-md-5"
                    id="traza"
                    style="display: none; position: absolute"
                  >
                    <div
                      class="card card-frontera card-outline collapsed-header"
                      id="trazaHeader"
                    >
                      <div class="card-header pt-0 pb-0" id="headingOne">
                        <h5 class="mb-0">
                          <button
                            class="btn btn-link"
                            data-toggle="collapse"
                            data-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            <i class="fas fa-route text-success"></i>
                            <b>&nbsp;Administrar Traza</b>
                          </button>
                        </h5>
                      </div>
                      <div
                        id="collapseOne"
                        class="collapse show"
                        aria-labelledby="trazaHeader"
                        data-parent="#traza"
                      >
                        <div class="card-body">
                          <div class="row">
                            <div class="col-md-6">
                              <div class="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  id="checkServicioIn"
                                  v-model="filter.checkServicioIn"
                                  class="custom-control-input"
                                  :disabled="filter.checkServicioNa == true"
                                />
                                <label
                                  class="custom-control-label"
                                  for="checkServicioIn"
                                  >Servicio Interno</label
                                >
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  id="checkServicioNa"
                                  v-model="filter.checkServicioNa"
                                  class="custom-control-input"
                                  :disabled="filter.checkServicioIn == true"
                                />
                                <label
                                  class="custom-control-label"
                                  for="checkServicioNa"
                                  >Servicio Nacional</label
                                >
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div
                              class="form-group col-md-6"
                              v-if="filter.checkServicioNa"
                            >
                              <label># Servicio Nacional</label>
                              <input
                                type="number"
                                @keypress="onlyNumber"
                                class="form-control form-control-sm"
                                placeholder="Servicio"
                                v-model="filter.servicioNa_id"
                                :disabled="
                                  filter.vehiculo_id != '' ||
                                    filter.servicioIn_id != ''
                                "
                                style="font-size: 12px; background-color: #fff"
                              />
                            </div>
                            <div
                              class="form-group col-md-6"
                              v-if="filter.checkServicioIn"
                            >
                              <label># Servicio Interno</label>
                              <input
                                type="number"
                                @keypress="onlyNumber"
                                class="form-control form-control-sm"
                                placeholder="Servicio"
                                v-model="filter.servicioIn_id"
                                :disabled="
                                  filter.vehiculo_id != '' ||
                                    filter.servicioNa_id != ''
                                "
                                style="font-size: 12px; background-color: #fff"
                              />
                            </div>
                            <div class="form-group col-md-6">
                              <label>Vehículo</label>
                              <input
                                type="text"
                                class="form-control form-control-sm"
                                placeholder="Placa"
                                v-model="filter.vehiculo_id"
                                style="font-size: 12px; background-color: #fff"
                              />
                            </div>
                          </div>
                          <div id="div_fechas" v-show="filter.vehiculo_id">
                            <div class="row">
                              <div class="col-md-6">
                                <label>Fecha Inicio</label>
                                <input
                                  type="date"
                                  class="form-control form-control-sm"
                                  v-model="filter.fecha_inicio"
                                  style="font-size: 9px; background-color: #fff"
                                />
                              </div>
                              <div class="col-md-6">
                                <label>Fecha Fin</label>
                                <input
                                  type="date"
                                  class="form-control form-control-sm"
                                  v-model="filter.fecha_fin"
                                  style="font-size: 9px; background-color: #fff"
                                />
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div
                              class="form-group col-md-6"
                              v-if="
                                (puntosTraza.length && filter.vehiculo_id) ||
                                  (puntosTraza.length &&
                                    filter.servicioIn_id) ||
                                  (puntosTraza.length && filter.servicioNa_id)
                              "
                            >
                              <label>Cantidad</label>
                              <input
                                type="number"
                                class="form-control form-control-sm"
                                placeholder="Cantidad Puntos"
                                v-model="filter.cantidad"
                                style="font-size: 12px; background-color: #fff"
                              />
                            </div>
                            <div class="form-group col-md-6">
                              <br />
                              <div
                                class="btn-group float-right pt-1"
                                role="group"
                                v-if="puntosTraza[0] && filter.cantidad"
                              >
                                <button
                                  type="button"
                                  class="btn btn-success"
                                  id="atras"
                                  :disabled="!markersTraza[1]"
                                  @click="backPoint()"
                                >
                                  <i class="fas fa-angle-double-left"></i>
                                </button>
                                <button
                                  type="button"
                                  class="btn btn-success"
                                  id="adelante"
                                  :disabled="
                                    markersTraza.length == puntosTraza.length
                                  "
                                  @click="nextPoint()"
                                >
                                  <i class="fas fa-angle-double-right"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="card-footer">
                          <div class="btn-group">
                            <button
                              type="button"
                              class="btn btn-primary"
                              @click="showTraza()"
                              v-if="
                                (filter.fecha_inicio && filter.fecha_fin) ||
                                  filter.servicioIn_id ||
                                  filter.servicioNa_id
                              "
                            >
                              Buscar
                              <i class="fas fa-search"></i>
                            </button>
                            <button
                              type="button"
                              class="btn btn-dark"
                              @click="limpiarMapa()"
                            >
                              Limpiar
                              <i class="fas fa-trash"></i>
                            </button>
                            <button
                              v-if="
                                ((filter.fecha_inicio && filter.fecha_fin) ||
                                  filter.servicioIn_id ||
                                  filter.servicioNa_id) &&
                                  $store.getters.can('cs.mapas.excelTraza')
                              "
                              type="button"
                              class="btn bg-indigo text-white mr-2 align-top py-1"
                              style="cursor: pointer"
                              @click="generarTrazaExcel()"
                            >
                              <i
                                style="font-size: 21px"
                                class="far fa-file-excel"
                              ></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-md-12"
              v-if="
                Object.keys(vehiculosIn).length > 0 ||
                  Object.keys(vehiculosNa).length > 0 ||
                  Object.keys(vehiculosInact).length > 0
              "
            >
              <CsMapasListVehiculos ref="CsMapasListVehiculos" />
            </div>
            <div class="col-md-12">
              <a
                class="btn btn-block btn-dark"
                data-toggle="collapse"
                href="#cara"
                role="button"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                Conversiones colores vh
              </a>
              <div class="collapse" id="cara">
                <div class="card card-body">
                  <div class="row">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">Servicios</th>
                          <th scope="col">
                            <img
                              src="/img/crr_azul_oscuro.png"
                              class="figure-img img-fluid rounded"
                            />
                          </th>
                          <th scope="col">
                            <img
                              src="/img/crr_azul.png"
                              class="figure-img img-fluid rounded"
                            />
                          </th>
                          <th scope="col">
                            <img
                              src="/img/crr_rojo.png"
                              class="figure-img img-fluid rounded"
                            />
                          </th>
                          <th scope="col">
                            <img
                              src="/img/crr_amarillo.png"
                              class="figure-img img-fluid rounded"
                            />
                          </th>
                          <th scope="col">
                            <img
                              src="/img/crr_verde.png"
                              class="figure-img img-fluid rounded"
                            />
                          </th>
                          <th scope="col">
                            <img
                              src="/img/crr_morado.png"
                              class="figure-img img-fluid rounded"
                            />
                          </th>
                          <th scope="col">
                            <img
                              src="/img/crr_rojo.png"
                              class="figure-img img-fluid rounded"
                            />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">Internos</th>
                          <td>Creado</td>
                          <td>En ejecución</td>
                          <td>Finalizado</td>
                          <td>Firmado</td>
                          <td>Visto bueno</td>
                          <td>Liquidado</td>
                          <td>Inactivo</td>
                        </tr>
                        <tr>
                          <th scope="row">Nacionales</th>
                          <td>Creado</td>
                          <td>En ejecución</td>
                          <td>Finalizado</td>
                          <td>Firmado</td>
                          <td>Visto bueno</td>
                          <td>Liquidado</td>
                          <td>Inactivo</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <CsMapasOptions ref="CsMapasOptions" />
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import CsMapasOptions from "./CsMapasOptions";
import CsMapasListVehiculos from "./CsMapasListVehiculos";
import GmapCluster from "vue2-google-maps/dist/components/cluster";
import Loading from "../../../../components/Loading";
import "jquery-ui-dist/jquery-ui";
import $ from "jquery";
import { VBPopover } from "bootstrap-vue";

export default {
  name: "CsMapaIndex",
  directives: {
    "b-popover": VBPopover,
  },
  components: {
    CsMapasOptions,
    Loading,
    GmapCluster,
    CsMapasListVehiculos,
  },
  data() {
    return {
      cargando: false,
      infoContent: "",
      vehiculosNa: {},
      vehiculosIn: {},
      vehiculosInact: {},

      infoWindowPos: {
        lat: 0,
        lng: 0,
      },
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      form: {
        vehiculos_in: false,
        vehiculos_na: false,
        vehiculos_inact: false,
      },
      filter: {
        checkServicioNa: false,
        checkServicioIn: false,
        servicioIn_id: "",
        vehiculo_id: "",
        servicioNa_id: "",
      },
      listasForms: {},

      center: { lat: 4.651371, lng: -74.071509 },
      tilt: 45,
      heading: 90,
      zoom: 8,
      markers: [],

      markersTraza: [],
      places: [],
      currentPlace: null,
      locations: {},
      poligonos: [],
      circunferencias: [],
      puntoInicialLat: null,
      puntoInicialLng: null,
      puntoFinalLat: null,
      puntoFinalLng: null,
      infoPuntoFinal: "",
      infoPuntoInicial: "",
      puntofin: null,
      vehiculo_id: null,
      latitud: null,
      longitud: null,
      puntosTraza: [],
      traza: false,
      path: [],
    };
  },

  methods: {
    async getIndex(page = 1) {
      let filters = this.$refs.CsMapasOptions.filter;
      if (
        Object.keys(this.vehiculosIn).length > 0 ||
        Object.keys(this.vehiculosNa).length > 0 ||
        Object.keys(this.vehiculosInact).length > 0
      ) {
        let filters2 = this.$refs.CsMapasListVehiculos.filtros;
        Object.assign(filters, filters2);
      }
      filters.vehiculos_in = await this.form.vehiculos_in;
      filters.vehiculos_na = await this.form.vehiculos_na;
      filters.vehiculos_inact = await this.form.vehiculos_inact;
      let me = this;
      if (
        this.form.vehiculos_in ||
        this.form.vehiculos_na ||
        this.form.vehiculos_inact
      ) {
        me.cargando = true;
        await axios
          .get("/api/cs/mapas/tablaMapa?page=" + page, {
            params: filters,
          })
          .then(function(response) {
            me.vehiculosIn = response.data[0];
            me.vehiculosNa = response.data[1];
            me.vehiculosInact = response.data[2];
            me.cargando = false;
          });
      } else {
        me.vehiculosIn = {};
        me.vehiculosNa = {};
        me.vehiculosInact = {};
      }
    },

    async showCarros(act) {
      this.markers = [];
      let filters = this.$refs.CsMapasOptions.filter;
      if (
        Object.keys(this.vehiculosIn).length > 0 ||
        Object.keys(this.vehiculosNa).length > 0 ||
        Object.keys(this.vehiculosInact).length > 0
      ) {
        let filters2 = this.$refs.CsMapasListVehiculos.filtros;
        Object.assign(filters, filters2);
      }

      filters.vehiculos_in = await this.form.vehiculos_in;
      filters.vehiculos_na = await this.form.vehiculos_na;
      filters.vehiculos_inact = await this.form.vehiculos_inact;

      if (
        this.form.vehiculos_in ||
        this.form.vehiculos_na ||
        this.form.vehiculos_inact
      ) {
        if (act == 1) {
          this.cargando = true;
        }
        //this.cargando = true;
        await axios
          .get("/api/cs/mapas/showCarros", {
            params: filters,
          })
          .then((response) => {
            this.getIndex();
            let loc = response.data;
            loc.forEach((loc1) => {
              const marker = {
                lat: parseFloat(loc1.lat),
                lng: parseFloat(loc1.lon),
              };
              this.markers.push({
                position: marker,
                icon: loc1.icon,
                info: loc1.ventana,
                center: {
                  marker,
                },
                vehi_id: loc1.id,
              });
            });
            this.cargando = false;
          })
          .catch(function(error) {
            this.cargando = false;
          });
      } else {
        this.getIndex();
      }

      /* window.Echo.channel("GPSTep").listen("GPSTep", (e) => {
        const marker2 = {
          lat: parseFloat(e.GPSTep.dblLatitud),
          lng: parseFloat(e.GPSTep.dblLongitud),
        };

        let mfind = this.markers.find((m) => m.vehi_id == e.GPSTep.vehiculo_id);

        if (mfind == undefined) {
          this.markers.push({
            position: marker2,
            icon: "/img/icons/car3.png",
            info: this.setVentana(e.GPSTep),
            center: {
              marker2,
            },
            vehi_id: e.GPSTep.vehiculo_id,
          });
        } else {
          let pos = this.markers.indexOf(mfind);
          this.animatedMove(pos, this.markers[pos].position, marker2);
          this.markers[pos].info = this.setVentana(e.GPSTep);
        }
      }); */
    },

    generarListadoExcel() {
      let filters = this.$refs.CsMapasOptions.filter;

      if (
        Object.keys(this.vehiculosIn).length > 0 ||
        Object.keys(this.vehiculosNa).length > 0
      ) {
        let filters2 = this.$refs.CsMapasListVehiculos.filtros;
        Object.assign(filters, filters2);
      }

      filters.vehiculos_in = this.form.vehiculos_in;
      filters.vehiculos_na = this.form.vehiculos_na;

      axios({
        method: "POST",
        url: "/api/cs/mapas/export",
        data: { filtros: filters },
      })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          location.href = data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },

    generarTrazaExcel() {
      axios({
        method: "POST",
        url: "/api/cs/mapas/exportTraza",
        data: { filtros: this.filter },
      })
        .then((response) => {
          let data = response.data;
          if (data.msj) {
            this.$swal({
              icon: "error",
              title: data.msj,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          } else {
            this.cargando = false;
            location.href = data.url;
          }
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.$parent.cargando = false;
        });
    },

    animatedMove(pos, current, moveto) {
      let lat = current.lat;
      let lng = current.lng;

      let deltalat = (moveto.lat - lat) / 10;
      let deltalng = (moveto.lng - lng) / 10;
      let me = this;
      for (let i = 0; i < 10; i++) {
        (function(ind) {
          setTimeout(function() {
            lat += deltalat;
            lng += deltalng;

            me.markers[pos].position = {
              lat: parseFloat(lat),
              lng: parseFloat(lng),
            };
          }, 100 * ind);
        })(i);
      }
    },

    async showDraggable() {
      if (await this.traza) {
        $("#traza").css("display", "block");
        $("#traza").draggable();
      } else {
        $("#traza").css("display", "none");
      }
    },

    pollData() {
      this.polling = setInterval(() => {
        this.showCarros(2);
      }, 180000);
    },

    showTraza() {
      let me = this;
      me.cargando = true;
      me.markersTraza = [];
      if (me.filter.servicioIn_id || me.filter.servicioNa_id) {
        axios
          .get("/api/cs/mapas/traza", {
            params: me.filter,
          })
          .then((response) => {
            me.cargando = false;
            if (response.data.arregloServicioIn) {
              me.filter.vehiculo_id = "";
              me.puntosTraza = response.data.arregloServicioIn;
              if (me.puntosTraza.length > 0) {
                const marker = {
                  lat: parseFloat(me.puntosTraza[0].latitud),
                  lng: parseFloat(me.puntosTraza[0].longitud),
                };
                me.markersTraza.push({
                  id: me.puntosTraza[0].id,
                  position: marker,
                  info: me.puntosTraza[0].ventana,
                  icon: "/img/icon_punto_g_ok.png",
                });
                me.center = marker;
                me.zoom = 10;
              } else {
                me.$swal({
                  icon: "error",
                  title: "No existe traza para este Servicio",
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
              }
            } else if (response.data.arregloServicioNa) {
              me.filter.vehiculo_id = "";
              me.puntosTraza = response.data.arregloServicioNa;
              if (me.puntosTraza.length > 0) {
                const marker = {
                  lat: parseFloat(me.puntosTraza[0].latitud),
                  lng: parseFloat(me.puntosTraza[0].longitud),
                };
                me.markersTraza.push({
                  id: me.puntosTraza[0].id,
                  position: marker,
                  info: me.puntosTraza[0].ventana,
                  icon: "/img/icon_punto_g_ok.png",
                });
                me.center = marker;
                me.zoom = 10;
              } else {
                me.$swal({
                  icon: "error",
                  title: "No existe traza para este Servicio",
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
              }
            } else if (response.data.mensaje) {
              me.$swal({
                icon: "error",
                title: response.data.mensaje,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            }
          })
          .catch(function(error) {
            me.cargando = false;
          });
      } else if (me.filter.vehiculo_id) {
        me.filter.servicioIn_id = "";
        me.filter.servicioNa_id = "";
        if (me.filter.fecha_inicio > me.filter.fecha_fin) {
          me.filter.fecha_fin = null;
          me.filter.fecha_inicio = null;
          me.$swal({
            icon: "error",
            title: `La fecha inicial no puede ser mayor a la fecha final...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          me.cargando = false;
        } else {
          var fecha_menor = new Date(me.filter.fecha_inicio);
          var fecha_mayor = new Date(me.filter.fecha_fin);
          var diff = fecha_mayor - fecha_menor;
          var fecha_limite = diff / (1000 * 60 * 60 * 24);

          if (fecha_limite > 5) {
            me.filter.fecha_fin = null;
            me.filter.fecha_inicio = null;
            me.$swal({
              icon: "error",
              title: `Las fechas no pueden ser mayor a 5 días...`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            me.cargando = false;
          } else {
            axios
              .get("/api/cs/mapas/traza", {
                params: me.filter,
              })
              .then((response) => {
                me.cargando = false;
                if (response.data.arregloVehiculo2) {
                  me.puntosTraza = response.data.arregloVehiculo2;

                  const marker = {
                    lat: parseFloat(me.puntosTraza[0].latitud),
                    lng: parseFloat(me.puntosTraza[0].longitud),
                  };
                  me.markersTraza.push({
                    id: me.puntosTraza[0].id,
                    position: marker,
                    info: me.puntosTraza[0].ventana,
                    icon: "/img/icon_punto_g_ok.png",
                  });
                  me.center = marker;
                  me.zoom = 10;
                } else if (response.data.mensaje) {
                  me.$swal({
                    icon: "error",
                    title: response.data.mensaje,
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                  });
                }
              })
              .catch(function(error) {
                me.cargando = false;
              });
          }
        }
      }
    },

    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },

    nextPoint() {
      let dist =
        parseInt(this.markersTraza.length - 1) + parseInt(this.filter.cantidad);
      for (let i = this.markersTraza.length - 1; i < dist; i++) {
        if (this.markersTraza.length < this.puntosTraza.length) {
          const marker = {
            lat: parseFloat(this.puntosTraza[i].latitud),
            lng: parseFloat(this.puntosTraza[i].longitud),
          };
          this.markersTraza.push({
            id: this.puntosTraza[i].id,
            position: marker,
            info: this.puntosTraza[i].ventana,
            icon: "/img/icon_viaje.png",
          });
          this.center = marker;
          this.zoom = 10;
        } else {
          const marker = {
            lat: parseFloat(this.puntosTraza[i].latitud),
            lng: parseFloat(this.puntosTraza[i].longitud),
          };
          this.markersTraza.push({
            id: this.puntosTraza[i].id,
            position: marker,
            info: this.puntosTraza[i].ventana,
            icon: "/img/icon_punto_g.png",
          });
          this.$swal({
            title: "Fin del recorrido!",
            text: "No se encontraron más tramas para este servicio",
            icon: "info",
          });

          break;
        }
      }
    },

    backPoint() {
      let dist =
        parseInt(this.markersTraza.length - 1) - parseInt(this.filter.cantidad);
      for (let i = this.markersTraza.length - 1; i > dist; i--) {
        this.markersTraza.splice(i, 1);
      }
    },

    limpiarMapa() {
      this.puntosTraza = [];
      this.markersTraza = [];
      this.viajes = [];
      this.filter.checkServicioNa = false;
      this.filter.checkServicioIn = false;
      this.filter.servicioIn_id = "";
      this.filter.vehiculo_id = "";
      this.filter.servicioNa_id = "";
    },

    setDataMarker(pos) {},

    setVentana(gps) {
      return (
        '<div class="wrapper"><h3 style="font-size:15px" class="text-center">Vehículo: ' +
        gps.strPlaca +
        "</h3><hr><b>Fecha reporte: </b> " +
        gps.dteFechaReporte +
        "<br> <b>Posicion: </b>" +
        gps.dblLatitud +
        ", " +
        gps.dblLongitud +
        "<br> <b>Velocidad: </b> " +
        gps.intVelocidadInstantanea +
        " Km/h" +
        "<br> <b>Direccion:</b> " +
        gps.strDireccion +
        "</div>"
      );
    },

    cleanMarkers() {
      this.markersTraza = [];
    },

    toggleInfoWindow: function(marker, idx, ventana) {
      this.infoWindowPos = marker.position;
      this.infoContent = ventana;
      this.center = marker.position;

      //comprueba si es el mismo marcador que se seleccionó y en caso afirmativo alternar
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //si un marcador diferente establece la ventana de información para abrir y restablecer el índice de marcador actual
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },

    toggleInfoWindowPoli: function(poli, idx, ventana) {
      this.infoWindowPos = poli.path[0];
      this.infoContent = ventana;
      this.center = poli.path[0];

      //compruebe si es el mismo marcador que se seleccionó y en caso afirmativo alternar
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //si un marcador diferente establece la ventana de información para abrir y restablecer el índice de marcador actual
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },

    toggleInfoWindowCir: function(cir, idx, ventana) {
      this.infoWindowPos = cir.center;
      this.infoContent = ventana;
      this.center = cir.center;

      //compruebe si es el mismo marcador que se seleccionó y en caso afirmativo alternar
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //si un marcador diferente establece la ventana de información para abrir y restablecer el índice de marcador actual
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },
  },

  beforeDestroy() {
    clearInterval(this.polling);
  },

  created() {
    this.pollData();
  },
  mounted() {},
};
</script>
